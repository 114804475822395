export const MY_AVAILABILITY_SLICE = 'myAvailability' as const;

export const TIME_SLOTS = [
  '12am',
  '1am',
  '2am',
  '3am',
  '4am',
  '5am',
  '6am',
  '7am',
  '8am',
  '9am',
  '10am',
  '11am',
  '12pm',
  '1pm',
  '2pm',
  '3pm',
  '4pm',
  '5pm',
  '6pm',
  '7pm',
  '8pm',
  '9pm',
  '10pm',
  '11pm',
];

// TODO: hardcoded to start with Sunday https://joinhomebase.atlassian.net/browse/FE-2593
//  Should be dynamic based on settings/schedule enforcement/start of work week
export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const INTERNAL_TIME_SLOTS = Array(48)
  .fill(null)
  .map((_, i) => {
    const hour = Math.floor(i / 2);
    const minute = (i % 2) * 30;
    return { hour, minute };
  });
