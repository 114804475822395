import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { RootState } from 'initializers/types';
import { createSelector } from 'reselect';

import { formatDate } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import { MY_AVAILABILITY_SLICE } from './constants';

export type AvailabilityStatus =
  | 'approved'
  | 'pending'
  | 'rejected'
  | 'unsubmitted';

export type Schedule = {
  type: string;
  start: string;
  end: string;
  locations: string;
  startCol: number;
  colSpan: number;
  id?: number;
  isAddAvailabilityCell?: boolean;
};

export type ScheduleRow = {
  schedules: Schedule[];
};

export type DayData = {
  day: string;
  scheduleRows: ScheduleRow[];
};
export interface Job {
  id: number;
  location_name: string;
  location_id: number;
}

interface User {
  id: number;
  employed: boolean;
}

export interface Availability {
  title: string;
  weekday: number;
  all_day: boolean;
  owner_type: 'Job' | 'Account';
  owner_id: number;
  start_at: string | null;
  end_at: string | null;
  id?: number;
}

export interface AvailabilityTemplate {
  id: number;
  employee_notes: string | null;
  manager_notes: string | null;
  user_id: number;
  status: AvailabilityStatus;
  availabilities: Availability[];
  start_at: string;
  current: boolean;
  responding_user_full_name: string | null;
  responding_at?: string;
}

export interface AvailabilityResponse {
  jobs: Job[];
  user: User;
  availability_templates: AvailabilityTemplate[];
  approval_required: boolean;
}

interface MyAvailabilityState {
  data: AvailabilityResponse | null;
  selectedTemplateId: number | null;
  loading: boolean;
  error: string | null;
}

const initialState: MyAvailabilityState = {
  data: null,
  selectedTemplateId: null,
  loading: true,
  error: null,
};

// THUNKS
export const fetchUserAvailabilities = createAsyncThunk(
  `${MY_AVAILABILITY_SLICE}/fetchUserAvailabilities`,
  async () => fetchJSON('/user/availability.json')
);

const myAvailabilitySlice = createSlice({
  name: MY_AVAILABILITY_SLICE,
  initialState,
  reducers: {
    setSelectedTemplateId: (state, action: PayloadAction<number>) => {
      state.selectedTemplateId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserAvailabilities.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserAvailabilities.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Set the selected template ID if it's not already set
        if (state.selectedTemplateId === null) {
          const currentTemplate = action.payload.availability_templates.find(
            (template: AvailabilityTemplate) => template.current
          );

          // Current template will always exist per backend guarantees
          state.selectedTemplateId = currentTemplate.id;
        }
      })
      .addCase(fetchUserAvailabilities.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch availability data';
      });
  },
});

// SELECTORS
export const selectUserAvailability = (state: RootState) =>
  state.get(MY_AVAILABILITY_SLICE).data;

export const selectUserAvailabilityLoading = (state: RootState) =>
  state.get(MY_AVAILABILITY_SLICE).loading;

export const selectSelectedTemplateId = (state: RootState) =>
  state.get(MY_AVAILABILITY_SLICE).selectedTemplateId;

export const selectAvailabilityTemplates = (state: RootState) =>
  state.get(MY_AVAILABILITY_SLICE).data?.availability_templates || [];

export const selectSelectedTemplate = createSelector(
  [selectSelectedTemplateId, selectAvailabilityTemplates],
  (templateId, templates) => {
    if (!templateId) return null;

    return templates.find(
      (template: AvailabilityTemplate) => template.id === templateId
    );
  }
);

export const selectTemplateSelectOptions = createSelector(
  [selectAvailabilityTemplates],
  templates => {
    // Sort templates by date (most recent first)
    // Current template should always be first
    const sortedTemplates = [...templates].sort((a, b) => {
      if (a.current) return -1;
      if (b.current) return 1;
      return b.start_at.localeCompare(a.start_at);
    });

    return sortedTemplates.map((template: AvailabilityTemplate) => ({
      value: template.id,
      label: template.current
        ? toI18n('my_availability_react.current')
        : formatDate(template.start_at, 'month_day_year_short'),
    }));
  }
);

export const { reducer, actions } = myAvailabilitySlice;
export const { setSelectedTemplateId } = actions;
